import React from 'react'
import './yoyogallery.css'
import SectionCaption from '../../layouts/sectioncaption.js'
import '../../pages/base.css'
import Gallery from '../gallery.js'

class YoyoGallery extends React.Component{
    constructor(props) {
        super(props)
      }

    render() {
        return (
            <div className="YoyoGalleryGroup">
                <SectionCaption
                    title="好好生活"
                    emoji="🎬"
                    subtitle="生活其实很简单生出来活下去"
                />
                <Gallery data={this.props.data} />
            </div>
        )
    }
}


export default YoyoGallery