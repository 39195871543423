import React from 'react'
import VlogCard from './vlogcard'

import SectionCaption from '../../layouts/sectioncaption.js'
import './vlogs.css'
import '../../pages/base.css'

const Vlogs = ({ data }) => (
    <div className="Vlogs">
        <SectionCaption
            title="记录生活"
            emoji="🎥"
            subtitle="让我们彼此分享互相陪伴吧 一起面对人生这一刻孤独吧"
        />

        <div className="VlogCardGroup">
            {data.map(edge => (
                <VlogCard 
                    title={edge.node.title}
                    time={edge.node.time}
                    src={edge.node.src}
                    poster={edge.node.poster.fluid.src}
                />
            ))}
            {/* <VlogCard 
                title="吉吉的Vlog Time 0"
                time="2020/08/20"
                src="https://video-1259037249.cos.ap-shanghai.myqcloud.com/vlog-for-yoyo-00.mp4"
                poster={cover}
            /> */}
        </div>
    </div>
)

export default Vlogs
