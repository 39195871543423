import React from 'react'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'

import './with-yoyo.css'

import Layout from '../layouts/layout'
import Hero from '../components/hero'
import WaveSection from '../layouts/wavesection'
import Vlogs from '../components/WithYoyo/vlogs'
import LoadingCover from '../components/WithYoyo/loadingcover.js'
import DoubleSeven from '../components/WithYoyo/doubleseven.js'
import CountingDay from '../components/WithYoyo/countingday.js'
import WechatRecord from '../components/WithYoyo/wechatrecord.js'
import YoyoGallery from '../components/WithYoyo/yoyogallery.js'

import bg2 from '../../images/with-yoyo/with-yoyo-bg1.jpg'
import bg3 from '../../images/with-yoyo/with-yoyo-bg2.jpg'
import bg1 from '../../images/with-yoyo/with-yoyo-bg3.jpg'
import bg4 from '../../images/with-yoyo/with-yoyo-bg4.jpg'
import YoyoAvatar from '../../images/avatar/yoyo-avatar.jpg'

class WithYoyo extends React.Component{

    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')

        const heros = get(this, 'props.data.allContentfulHero.edges')

        const vlogs = get(this, 'props.data.allContentfulVlogWithYoyo.edges')

        const socialmedias = get(this, 'props.data.allContentfulSocialMedia.edges')

        const wechatrecords = get(this, 'props.data.allContentfulWechatRecord.edges')

        const recordingsongs = get(this, 'props.data.allContentfulRecordingSong.edges')

        const photogallerys = get(this, 'props.data.allContentfulPhotoGallery.edges')

        return (
            
            <Layout location={this.props.location} socialmedias={socialmedias}>
                <Helmet title="zz和yoyo的故事" />

                <LoadingCover />

                <Hero data={heros} />        

                <CountingDay />


                <WaveSection 
                  onWaveSectionChange={this.onWaveSectionChange}
                  image={bg4}
                  logo={YoyoAvatar}
                  text="你推窗望进来，瞳孔是距我最近的星✨"
                />

                <YoyoGallery data={photogallerys}/>


                <WaveSection 
                  onWaveSectionChange={this.onWaveSectionChange}
                  image={bg2}
                  logo={YoyoAvatar}
                  text="喜欢的人会一直在身边"
                />

                <WechatRecord data={wechatrecords} />

                <WaveSection 
                  onWaveSectionChange={this.onWaveSectionChange}
                  image={bg1}
                  logo={YoyoAvatar}
                  text="喜欢的人会一直在身边"
                />
                
                <Vlogs data={vlogs} />


                <WaveSection 
                  onWaveSectionChange={this.onWaveSectionChange}
                  image={bg3}
                  logo={YoyoAvatar}
                  text="喜欢的人会一直在身边"
                />

                <DoubleSeven data={recordingsongs} />

            </Layout>
        )
    }
}

export default WithYoyo

export const pageQuery = graphql`
  query VlogsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulHero(sort: { fields: [createdAt], order: ASC }, filter: {page: {eq: "yoyo"}}) {
      edges {
        node {
          name
          description
          quote
          bg {
            fluid(maxWidth: 1000){
              sizes
              src
              srcSet
            }
          }
          createdAt
          page
        }
      }
    }
    allContentfulSocialMedia (sort: { fields: [orderRank, createdAt], order: ASC }) {
      edges {
        node {
          fontclass
          color
          url
          title
        }
      }
    }
    allContentfulVlogWithYoyo (sort: { fields: [time], order: DESC }) {
      edges {
        node {
            title
            time
            src
            poster {
              fluid(maxWidth: 1000){
                sizes
                src
                srcSet
              }
            }
        }
      }
    }
    allContentfulWechatRecord(sort: { fields: [time], order: ASC }) {
      edges {
        node {
          time(formatString: "YYYY-DD-MM")
          records {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
    allContentfulRecordingSong(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          createdAt
          title
          lyric
          src {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulPhotoGallery(sort: { fields: [time], order: DESC }, filter: {withYoyo: {eq: true}}) {
      edges {
        node {
          topic
          text1
          text2
          text3
          textEn1
          textEn2
          textEn3
          album {
            fluid {
              src
            }
          }
          time
          withYoyo
        }
      }
    }
  }
`