import React from 'react'
import './doubleseven.css'
import SectionCaption from '../../layouts/sectioncaption.js'
import '../../pages/base.css'
import RecordingSong from './recordingsong.js'

class DoubleSeven extends React.Component{
    constructor(props) {
        super(props)
      }

    componentDidMount() {
        this.initLyric();
        this.initLyricAnimation();
    }

    initLyric() {
        var lyrics = document.querySelectorAll(".RecordingSongGroup .Text");
        lyrics.forEach((lyric, index) => {
            for(let ch of this.props.data[index].node.lyric.split("").reverse().join("")){
                var chDiv = document.createElement("div");
                chDiv.innerHTML = ch;
                lyric.appendChild(chDiv);
            }
        })      
    }

    initLyricAnimation(){
        document.querySelectorAll('.MusicGroup .Text div').forEach((elem, index) => {
            elem.style.animationDelay = index*0.2 + 's';
        });
    }

    render() {
        return (
            <div className="DoubleSevenGroup">
                <SectionCaption
                    title="牛郎织女"
                    emoji="👩‍❤️‍👨"
                    subtitle="心跳不会骗自己"
                />

                <div className="MusicGroup">
                    {this.props.data.map(edge => (
                        <RecordingSong 
                            title={edge.node.title}
                            lyric={edge.node.lyric}
                            src={edge.node.src.file.url}
                        />
                    ))}
                </div>
            </div>
        )
    }
}


export default DoubleSeven
