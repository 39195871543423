import React from 'react'
import SectionCaption from '../../layouts/sectioncaption.js'

import './wechatrecord.css'

import iPhoneModel from '../../../images/iphone.png'
import YoyoAvatar from '../../../images/avatar/yoyo-avatar.jpg'
import zzAvatar from '../../../images/avatar/dbz-avatar.jpg'

class WechatRecord extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.data
        }
    }
    componentDidMount() {
        // this.handleWechatRecordContent();
        this.handleBubbleEffect();
    }

    handleBubbleEffect = () => {
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
              if(entry.isIntersecting){
                entry.target.classList.add('animate')
                // observer.unobserve(entry.target)
              } else {
                entry.target.classList.remove('animate')
              }
            })
        })
        
        document.querySelectorAll('.info-group').forEach(yoyo => {
          observer.observe(yoyo);
        });
    }

    handleWechatRecordContent = (data) => {
        this.state.data.map(({ node }) => {
            var time = node.time
            var records = node.records.childMarkdownRemark.rawMarkdownBody.split("\n");
            records = records.map((record) => {
                var item = {}
                record = record.substr(2);
                if(record.startsWith("yoyo")){
                    item.who = "yoyo";
                    item.what = record.substr(6);
                } else if(record.startsWith("zz")) {
                    item.who = "zz";
                    item.what = record.substr(4);
                }
                return item
            });
            // records.pop();
            // console.log(records);

            var recordContainer = document.getElementById("wechat-record-container");
            
            // Time
            var timeDiv = document.createElement("div");
            timeDiv.classList.add("time");
            timeDiv.innerHTML = time;
            recordContainer.appendChild(timeDiv);

            records.map((record) => {
                //Bubble
                var infoGroupDiv = document.createElement("div");
                infoGroupDiv.classList.add("info-group");
                infoGroupDiv.classList.add(record.who=="yoyo" ? "yoyo" : "zz");
                var avatarImg = document.createElement("img");
                avatarImg.classList.add("avatar");
                avatarImg.src = record.who=="yoyo" ? YoyoAvatar : zzAvatar;
                var bubbleDiv = document.createElement("div");
                bubbleDiv.classList.add("bubble");
                bubbleDiv.innerHTML = record.what;

                if(record.who=="zz"){
                    infoGroupDiv.appendChild(avatarImg);
                    infoGroupDiv.appendChild(bubbleDiv);
                } else if (record.who=="yoyo"){
                    infoGroupDiv.appendChild(bubbleDiv);
                    infoGroupDiv.appendChild(avatarImg);
                }
                recordContainer.appendChild(infoGroupDiv);
            });
        })
    }
    
    render() {
        return (
            <div className="WechatRecord">
                <SectionCaption
                    title="说不完的话"
                    emoji="🎙"
                    subtitle="陪伴是最长情的告白"
                />
                <div className="WechatRecordGroup">
                <img className="model" src={iPhoneModel} />
                
                <div className="name">
                    <p class="blank"></p>
                    <p>🍬🍦</p>
                </div>

                <div id="wechat-record-container" className="wechat-record-container">
                    <div className="gap"></div>
                    <div className="time">2020年4月08日</div>
                    <div className="info-group zz">
                        <img className="avatar" src={zzAvatar} alt="yoyo avatar" />
                        <div className="bubble">
                            🌙
                        </div>
                    </div>            
                    <div className="info-group yoyo">
                        <div className="bubble">
                            🌙
                        </div>
                        <img className="avatar" src={YoyoAvatar} alt="yoyo avatar" />
                    </div>

                    <div className="time">2020年8月11日</div>
                    <div className="info-group zz">
                        <img className="avatar" src={zzAvatar} alt="yoyo avatar" />
                        <div className="bubble">
                            🌙
                        </div>
                    </div>            
                    <div className="info-group yoyo">
                        <div className="bubble">
                            🌙
                        </div>
                        <img className="avatar" src={YoyoAvatar} alt="yoyo avatar" />
                    </div>

                    <div className="time">2020年9月04日</div>
                    <div className="info-group zz">
                        <img className="avatar" src={zzAvatar} alt="yoyo avatar" />
                        <div className="bubble">
                            🌙
                        </div>
                    </div>            
                    <div className="info-group yoyo">
                        <div className="bubble">
                            🌙
                        </div>
                        <img className="avatar" src={YoyoAvatar} alt="yoyo avatar" />
                    </div>

                    <div className="time">2020年12月13日</div>
                    <div className="info-group zz">
                        <img className="avatar" src={zzAvatar} alt="yoyo avatar" />
                        <div className="bubble">
                            🌙
                        </div>
                    </div>            
                    <div className="info-group yoyo">
                        <div className="bubble">
                            🌙
                        </div>
                        <img className="avatar" src={YoyoAvatar} alt="yoyo avatar" />
                    </div>

                    <div className="time">2023年06月01日</div>
                    <div className="info-group zz">
                        <img className="avatar" src={zzAvatar} alt="yoyo avatar" />
                        <div className="bubble">
                            🌙
                        </div>
                    </div>            
                    <div className="info-group yoyo">
                        <div className="bubble">
                            🌙
                        </div>
                        <img className="avatar" src={YoyoAvatar} alt="yoyo avatar" />
                    </div>
                </div>

            </div>
        
            </div>
        )
    }
}

export default WechatRecord