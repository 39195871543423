import React from 'react'
import './vlogcard.css'

class VlogCard extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount(){
        ['fullscreenchange','webkitfullscreenchange','mozfullscreenchange'].forEach((item) => {
            window.addEventListener(item, () => this.fullscreenchange());
        });
    }

    //  监听全屏事件触发
    fullscreenchange() {
        let isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (isFullScreen) {
          //  进入全屏
          document.querySelectorAll("video").forEach(function(videoItem){
            videoItem.style.objectFit = "contain";
          });
        } else {
          //  退出全屏
          document.querySelectorAll("video").forEach(function(videoItem){
            videoItem.style.objectFit = "cover";
          });
        }
      }

    render(){
        return (
            <div className="VlogCard">
                <video src={this.props.src} controls="controls" poster={this.props.poster} draggable="false">
                    您的浏览器不支持 video 标签
                </video>
                <div className="TextGroup">
                    <h2 className="title">{this.props.title}</h2>
                    <div className="time">{this.props.time}</div>
                </div>
            </div>
        )
    }
}

export default VlogCard