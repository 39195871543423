import React from 'react'
import './recordingsong.css'

class RecordingSong extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="RecordingSongGroup">
                <h3 className="title">{this.props.title}</h3>

                <p className="Text"></p>
                
                <audio controls="controls">
                    <source src={this.props.src} type="audio/ogg" />
                    Your browser does not support this audio format.
                </audio>
            </div>
        )
    }
}

export default RecordingSong